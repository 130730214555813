import { AnimatePresence, motion } from 'framer-motion';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { LinkPlus, LinkPlusProps } from '../common';
import { RedactMark } from '../common/RedactMark';
import { getPageExitClick } from '../common/transitions/page-exit';

type TimelineItemProps = {
  categoryColor?: string;
  category?: string;
  slug?: string;
  hasVisited?: boolean;
  shortName?: string;
  caseName?: string;
  selected?: boolean;
  caseYear?: number;
  width: number;
  image?: IGatsbyImageData;
  onLinkClick: (string) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};

export const TimelineItem: FC<TimelineItemProps> = ({
  categoryColor = 'black',
  category,
  slug,
  hasVisited = false,
  selected = false,
  shortName,
  caseName,
  caseYear,
  width,
  image,
  onLinkClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const imageVariants = useMemo(
    () => ({
      initial: {
        opacity: 0,
      },
      animate: {
        opacity: 1,
        transition: {
          duration: 0.3,
          ease: 'easeInOut',
        },
      },
      exit: {
        opacity: 0,
        transition: {
          delay: 0.15,
          duration: 0.25,
        },
      },
    }),
    [],
  );

  const linkProps = useMemo(
    () =>
      ({
        nonDraggable: true,
        to: `/poem/${slug}`,
        onClick: getPageExitClick(`/poem/${slug}`, {
          onClick: () => onLinkClick(slug),
          seconds: 0.4,
        }),
      } as LinkPlusProps),
    [onLinkClick, slug],
  );

  return (
    <>
      <AnimatePresence>
        {selected && (
          <RedactMark
            duration={0.25}
            enterDelay={0.1}
            exitDelay={0.1}
            customCss={tw`[height:90%] [width:20.5rem] absolute [transform:rotate(-60deg)] origin-top-left md:([transform:rotate(-50deg)])`}
            color={categoryColor}
          />
        )}
      </AnimatePresence>
      <motion.div
        css={getLinkCss(hasVisited, selected)}
        onMouseMove={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <LinkPlus
          {...linkProps}
          title={`${shortName || caseName}\\n${category}`}
        />
        <AnimatePresence>
          {image && selected && (
            <motion.div
              variants={imageVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              tw="absolute bg-white [left:18rem] [top:-5rem] z-50 [width:135px] [transform:rotate(60deg)] 
                  md:([transform:rotate(50deg)] [top:-5.5rem]) 
                  after:(absolute [top:99%] -inset-x-0 bg-white h-2 w-full pointer-events-none)"
            >
              <LinkPlus {...linkProps}>
                <GatsbyImage image={image} alt="" />
              </LinkPlus>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
      <motion.div
        tw="relative flex justify-center [z-index:1]"
        onMouseMove={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {hasVisited && (
          <RedactMark
            color={categoryColor}
            customCss={tw`h-full w-full absolute [z-index:-1] top-0`}
            lighten
          />
        )}
        <AnimatePresence>
          {selected && (
            <RedactMark
              duration={0.35}
              exitDelay={0.3}
              customCss={tw`h-full w-full absolute [z-index:-1] top-0`}
              color={categoryColor}
            />
          )}
        </AnimatePresence>
        <LinkPlus
          aria-hidden
          {...linkProps}
          customCss={[
            yearCss,
            css`
              width: ${width}px;
              transition: color 0.3s linear;
            `,
            selected && tw`text-white`,
            !selected && hasVisited && tw`text-black`,
          ]}
          title={`${caseYear?.toString().slice(0, 2)}\\n${caseYear
            ?.toString()
            .slice(2)}`}
        />
      </motion.div>
    </>
  );
};

const yearCss = tw`text-lg font-secondary [line-height:1] text-center pt-2 pb-3`;

const getLinkCss = (hasVisited: boolean, selected: boolean) => [
  tw`[height:90%] pl-1 pr-5 no-underline font-secondary text-sm whitespace-nowrap 
    leading-tight absolute top-0 bottom-0 [transform:rotate(-60deg)] origin-top-left 
    my-auto flex flex-col justify-center md:([transform:rotate(-50deg)])`,
  css`
    transition: color 0.3s 0.1s linear;
    .link-title.line-1 {
      padding-left: 2rem;
      width: 100%;
    }
    .link-title.line-2 {
      padding-left: 2rem;
      font-size: 9px;
      text-align: right;
      margin-right: 1rem;
    }
  `,
  hasVisited &&
    css`
      .link-title.line-2 {
        /* color: #7e7d7d; */
      }
      &:hover > .link-title.line-2 {
        color: black;
      }
    `,
  tw`after:(absolute top-0 left-2 content-none w-32 [height:1px] bg-black [transition:all 0.3s])`,
  selected && tw`bg-none text-white after:(bg-transparent)`,
];
