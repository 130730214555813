import { Text } from '@chakra-ui/layout';
import React, { FC } from 'react';
import tw from 'twin.macro';
import { Category, CustomCssProps } from '~/config';
import { CategoryButton } from './CategoryButton';

type TimelineCategoriesProps = CustomCssProps & {
  categories: Category[];
  selectedCat: string;

  /** Callback for setting category state */
  onClick: (category: string) => void;
};

export const TimelineCategories: FC<TimelineCategoriesProps> = ({
  customCss,
  categories,
  selectedCat,
  onClick,
}) => {
  return (
    <Container css={customCss}>
      <Text tw="mb-4 text-sm">Filter the timeline by subject</Text>

      <ButtonRow>
        {categories.map((category) => {
          return (
            <CategoryButton
              selectedCat={selectedCat}
              category={category}
              key={category.slug}
              handleClick={() => onClick(category.slug)}
            ></CategoryButton>
          );
        })}
      </ButtonRow>
    </Container>
  );
};

const Container = tw.div`flex flex-col items-center`;

const ButtonRow = tw.div`grid grid-flow-col [grid-auto-columns:9.5rem] gap-4`;
