import { motion } from 'framer-motion';
import React, { FC, useMemo } from 'react';
import tw from 'twin.macro';
import { CustomCssProps } from '~/config';

type RedactMarkProps = CustomCssProps & {
  lighten?: boolean;
  color?: string;
  enterDelay?: number;
  exitDelay?: number;
  duration?: number;
};

export const RedactMark: FC<RedactMarkProps> = ({
  color = 'black',
  lighten,
  duration = 0.25,
  enterDelay = 0,
  exitDelay = 0,
  customCss,
  ...props
}) => {
  const rectVariants = useMemo(
    () => ({
      initial: {
        width: 0,
      },
      animate: {
        width: 128.52,
        transition: {
          duration,
          ease: [0.61, 0.43, 0.1, 0.92],
          delay: enterDelay,
        },
      },
      hover: {
        width: 128.52,
        transition: {
          duration,
          ease: [0.61, 0.43, 0.1, 0.92],
          delay: enterDelay,
        },
      },
      exit: {
        width: 0,
        transition: {
          duration,
          ease: [0.61, 0.43, 0.1, 0.92],
          delay: exitDelay,
        },
      },
    }),
    [duration, enterDelay, exitDelay],
  );
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128.52 30"
      preserveAspectRatio="none"
      css={[tw`pointer-events-none`, customCss]}
    >
      <motion.rect
        fill={color}
        opacity={lighten ? 0.15 : 1}
        x={0}
        y={0}
        height={30}
        variants={rectVariants}
        initial="initial"
        animate="animate"
        exit="exit"
      />
    </svg>
  );
};
