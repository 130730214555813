import { useEffect, useRef } from 'react';

/**
 * A hook for watching value changes and running a callback
 *
 * @param callback called when dependencies change
 * @param deps dependency array passed to use effect
 */
export const useWatcher = (callback, deps) => {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }

    callback();
  }, [
    callback,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...deps,
  ]);
};
