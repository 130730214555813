import { motion } from 'framer-motion';
import React, { FC, useMemo, useState } from 'react';
import tw, { css } from 'twin.macro';
import { Category } from '~/config';
import { useWatcher } from '~/hooks/useWatcher';
import { CategoryButton } from './CategoryButton';

type MobileCategoriesProps = {
  categories: Category[];
  selectedCat: string;

  /** Callback for setting category state */
  onCategoryClick: (category: string) => void;
  menuOpen: boolean;
  /** Callback for filter menu click */
  handleMenuOpen: (boolean) => void;
};

export const MobileCategories: FC<MobileCategoriesProps> = ({
  selectedCat,
  categories,
  menuOpen,
  handleMenuOpen,
  onCategoryClick,
}) => {
  const [selectedColor, setSelectedColor] = useState('all');
  const [catReadout, setCatReadout] = useState('');

  useWatcher(() => {
    if (categories) {
      const selectedCategory = categories.find((category) => {
        return category.slug === selectedCat;
      });
      if (selectedCategory && selectedCategory?.title !== 'All') {
        setSelectedColor(selectedCategory.color);
        setCatReadout(selectedCategory.title);
      } else {
        setSelectedColor('all');
        setCatReadout('');
      }
    }
  }, [selectedCat]);

  const rectArray = useMemo(
    () => [
      {
        fill: 'rgb(var(--brand-coral))',
        height: 2,
        width: 20,
        x: 3,
        variants: {
          initial: {
            y: 4,
            opacity: 1,
          },
          animate: {
            y: 0,
            opacity: 0,
          },
        },
      },
      {
        fill: 'rgb(var(--brand-red-delicious))',
        height: 2,
        width: 15,
        x: 6,
        variants: {
          initial: {
            y: 8,
            opacity: 1,
          },
          animate: {
            y: 0,
            opacity: 0,
          },
        },
      },
      {
        fill: 'rgb(var(--brand-pumpkin))',
        height: 2,
        width: 10,
        x: 8,
        variants: {
          initial: {
            y: 12,
            opacity: 1,
          },
          animate: {
            y: 0,
            opacity: 0,
          },
        },
      },
      {
        fill: 'rgb(var(--brand-sky-blue))',
        height: 2,
        width: 4,
        x: 11,
        variants: {
          initial: {
            y: 16,
            opacity: 1,
          },
          animate: {
            y: 0,
            opacity: 0,
          },
        },
      },
      {
        fill: 'rgb(var(--brand-earth))',
        height: 2,
        width: 26,
        x: 0,
        variants: {
          initial: {
            y: 0,
            rotate: 0,
          },
          animate: {
            y: 9,
            rotate: -45,
            transition: {
              delay: 0.2,
            },
          },
        },
      },
      {
        fill: 'rgb(var(--brand-earth))',
        height: 2,
        width: 26,
        x: 0,
        variants: {
          initial: {
            y: 0,
            rotate: 0,
          },
          animate: {
            y: 9,
            rotate: 45,
            transition: {
              delay: 0.2,
            },
          },
        },
      },
    ],
    [],
  );

  const containerVariant = useMemo(
    () => ({
      initial: {
        height: 0,
        transition: {
          ease: 'easeOut',
          delay: 0.25,
        },
      },
      animate: {
        height: 'auto',
        transition: {
          ease: 'easeOut',
        },
      },
    }),
    [],
  );

  const contentVariants = useMemo(
    () => ({
      initial: {
        opacity: 0,
      },
      animate: {
        opacity: 1,
        transition: {
          delay: 0.25,
        },
      },
    }),
    [],
  );

  return (
    <Container>
      <motion.div
        variants={containerVariant}
        initial="initial"
        animate={menuOpen ? 'animate' : 'initial'}
        tw="bg-brand-gray-lightest flex justify-center absolute inset-x-0"
      >
        <motion.div
          variants={contentVariants}
          css={[
            tw`py-4 w-1/2 my-4 [max-width:300px]`,
            !menuOpen && tw`pointer-events-none`,
          ]}
        >
          <ButtonContainer>
            <p tw="text-xs">Select cases for the timeline</p>
            {categories.map((category) => (
              <CategoryButton
                selectedCat={selectedCat}
                category={category}
                key={category.slug}
                handleClick={() => {
                  handleMenuOpen(false);
                  onCategoryClick(category.slug);
                }}
                customCss={tw``}
              ></CategoryButton>
            ))}
          </ButtonContainer>
        </motion.div>
      </motion.div>

      <button
        onClick={() => handleMenuOpen(!menuOpen)}
        tw="absolute left-4 top-3.5 flex flex-col items-start text-sm"
      >
        <span
          css={[
            tw`[transition:opacity .15s ease-in-out]`,
            menuOpen && tw`opacity-0`,
            css`
              color: ${selectedColor === 'all' ? 'black' : selectedColor};
            `,
          ]}
        >
          <span tw="ml-2">Filter</span>
          {catReadout && <span tw="ml-5">{catReadout}</span>}
        </span>
        <motion.svg
          width="26"
          height="18"
          viewBox="0 0 26 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          tw="w-12"
        >
          {rectArray.map((rect, index) => (
            <motion.rect
              height={rect.height}
              width={rect.width}
              x={rect.x}
              key={index}
              fill={selectedColor === 'all' ? rect.fill : selectedColor}
              variants={rect.variants}
              initial="initial"
              animate={menuOpen ? 'animate' : 'initial'}
            />
          ))}
        </motion.svg>
      </button>
    </Container>
  );
};

const Container = tw.div`relative z-20`;
const ButtonContainer = tw.div`flex flex-col justify-between gap-4`;
