import { PageProps } from 'gatsby';
import React, { FC, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import tw from 'twin.macro';
import { DesktopOnly, Layout } from '~/components/common';
import { SEO } from '~/components/SEO';
import { MobileCategories } from '~/components/timeline/MobileCategories';
import { TimelineCategories } from '~/components/timeline/TimelineCategories';
import { TimelineSection } from '~/components/timeline/TimelineSection';
import { Frontmatter, LocalStorageKey } from '~/config';
import { useHasMounted, useTimelineQuery } from '~/hooks';
import Drag from '~/images/drag.svg';
import { getStoredSessionItem, storeSessionItem } from '~/utils';

// don't add export - uses local gatsby plugin
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Timeline',
  order: 1,
  screenShoot: false,
};

const Page: FC<PageProps> = () => {
  const { globalStrings, nodes, catNodes } = useTimelineQuery();
  const [selectedCat, setSelectedCat] = useState(
    getStoredSessionItem(LocalStorageKey.selectedCat) || 'all',
  );
  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false);
  const [hasDragged, setHasDragged] = useState(false);
  const hasMounted = useHasMounted();

  const handleCategoryClick = (category: string) => {
    const newCat = selectedCat === category ? 'all' : category;
    setSelectedCat(newCat);
    storeSessionItem(LocalStorageKey.selectedCat, newCat);
  };

  const handleFirstDrag = () => {
    setHasDragged(true);
  };

  const handleMobileMenuChange = (state: boolean) => {
    if (state) {
      handleCategoryMenuOpen(false);
    }
  };

  const handleCategoryMenuOpen = (value: boolean) => {
    setCategoryMenuOpen(value);
  };

  return (
    <Layout
      customCss={tw`[touch-action:pan-y]`}
      footer
      onMobileMenuChange={handleMobileMenuChange}
    >
      <SEO title={frontmatter.title} path="/timeline" />
      {hasMounted && (
        <div>
          {isMobileOnly && (
            <MobileCategories
              onCategoryClick={handleCategoryClick}
              menuOpen={categoryMenuOpen}
              handleMenuOpen={handleCategoryMenuOpen}
              selectedCat={selectedCat}
              categories={catNodes}
            />
          )}
          {/* The min-height below is to preserve of the size of the timeline */}
          <div tw="overflow-hidden [min-height:34rem] flex md:(px-8)">
            {/* top pad prevents filter label collision w/images on mobile */}
            <div tw="flex flex-col justify-center pt-8 md:(pt-0)">
              <TimelineSection
                nodes={nodes}
                selectedCat={selectedCat}
                onFirstDrag={handleFirstDrag}
              />
            </div>
          </div>
          <div tw="relative">
            <DesktopOnly>
              <InfoContainer css={hasDragged && tw`opacity-0`}>
                <Drag tw="[max-width:2.5rem]" />
                <p tw="[max-width:30ch] text-sm text-brand-gray">
                  {globalStrings.desk_drag_hint}
                </p>
              </InfoContainer>
            </DesktopOnly>
          </div>

          {/* Filter bar (at top level so it can be centered on screen) */}
          <DesktopOnly customCss={tw`w-full mb-4`}>
            <TimelineCategories
              customCss={tw`mt-10`}
              onClick={handleCategoryClick}
              categories={catNodes}
              selectedCat={selectedCat}
            />
          </DesktopOnly>
        </div>
      )}
    </Layout>
  );
};

export default Page;

const InfoContainer = tw.div`absolute [bottom:0] inset-x-0 z-0 px-4 mx-auto 
flex gap-4 items-center justify-center [transition:opacity .5s ease-in-out]`;
