import { motion, useSpring } from 'framer-motion';
import { debounce } from 'lodash';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import tw, { css } from 'twin.macro';
import { LocalStorageKey } from '~/config';
import { clamp, getStoredItem, storeItem } from '~/utils';
import { DesktopOnly } from '../common';
import { TimelineItem } from './TimelineItem';
import { useResize } from '~/hooks';

/**
 * Interactive historical timeline
 */
type TimelineSectionProps = {
  nodes;
  selectedCat: string;
  onFirstDrag: () => void;
};

const itemWidth = 50;
const itemMargin = 30;
const wheelCoefficent = 2;
const touchCoefficent = 1;

export const TimelineSection: FC<TimelineSectionProps> = ({
  nodes,
  selectedCat,
  onFirstDrag,
}) => {
  const containerRef = useRef<HTMLUListElement | null>(null);
  const [selectedNodes, setSelectedNodes] = useState(nodes);
  const firstDrag = useRef(false);

  const x = useSpring(0, { stiffness: 30 });
  const [highlighted, setHighlighted] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  // Reset x on category select change
  useEffect(() => {
    x.set(0);
  }, [selectedCat, x]);

  /**
   * Hooking into category changes and setting filtered nodes
   */
  useEffect(() => {
    setSelectedNodes(
      selectedCat === 'all'
        ? nodes
        : nodes.filter((node) => {
            return node?.category === selectedCat;
          }),
    );
  }, [nodes, selectedCat]);

  const getLowerClamp = useCallback(() => {
    // Mobile calc allows drag all the way to left side, for auto-highlight to work on all items
    const mobileCalc =
      -(selectedNodes.length - 1) * (itemWidth + 2 * itemMargin);
    if (isMobile) {
      return mobileCalc;
    }

    // Desk/tablet: determine whether timeine is scrollable, and set up scroll limit so last item's hover fits to right edge
    const timelineWidth = containerRef.current!.clientWidth + 100; // pad for last item
    const magicNumber = 420; // arrived at by experimentation. 😄 (to test, change window size around timeline width)
    if (timelineWidth + magicNumber >= window.innerWidth) {
      return mobileCalc + window.innerWidth - magicNumber;
    }

    // Large desk (or small timeline): visually center when no scrolling
    return (window.innerWidth - timelineWidth) / 2;
  }, [selectedNodes.length]);

  const resetXPosition = useCallback(() => {
    x.set(clamp(x.get(), getLowerClamp(), 0));
  }, [getLowerClamp, x]);

  // reset x position when category is updated
  useEffect(() => {
    resetXPosition();
    setTimeout(() => resetXPosition(), 1);
  }, [resetXPosition, selectedCat]);

  /**
   * Updating select on motionValue change
   */
  const handleSelectedUpdate = useCallback(() => {
    if (!firstDrag.current) {
      onFirstDrag();
      firstDrag.current = true;
    }
    if (isMobile) {
      const fullItemSize = itemWidth + 2 * itemMargin;
      let currentIndex;

      if (x.get() > -50) {
        currentIndex = Math.floor(Math.abs(x.get()) / fullItemSize);
      } else {
        currentIndex = Math.ceil(Math.abs(x.get()) / fullItemSize);
      }

      setHighlighted(clamp(currentIndex, 0, selectedNodes.length - 1));
    }
  }, [onFirstDrag, x, selectedNodes]);

  useEffect(() => {
    const unsubscribeX = x.onChange(handleSelectedUpdate);

    return unsubscribeX;
  }, [handleSelectedUpdate, nodes, highlighted, x]);

  const handleResize = useCallback(() => {
    resetXPosition();
  }, [resetXPosition]);

  useResize(handleResize);

  /**
   * Timeline Interactions
   */

  const handlePan = useCallback(
    (event, info) => {
      const newValue = clamp(
        x.get() + info.velocity.x * touchCoefficent,
        getLowerClamp(),
        0,
      );
      x.set(newValue);
    },
    [getLowerClamp, x],
  );

  useEffect(() => {
    const handleWheel = (event) => {
      if (window.innerHeight === document.body.clientHeight) {
        // Detect if there's a scroll bar on the browser
        if (window.innerWidth === document.body.clientWidth) {
          const newValue = clamp(
            x.get() + event.deltaY * wheelCoefficent,
            getLowerClamp(),
            0,
          );
          x.set(newValue);
        }
      }
    };

    document.addEventListener('wheel', handleWheel);

    return () => document.removeEventListener('wheel', handleWheel);
  }, [x, getLowerClamp]);

  /**
   * Prop Callbacks for children
   */
  const visitedLinks = useMemo(
    () => getStoredItem(LocalStorageKey.visitedLinks) || [],
    [],
  );

  const handleLinkClick = useCallback(
    (slug: string) => {
      if (!visitedLinks.includes(slug)) {
        storeItem(LocalStorageKey.visitedLinks, [...visitedLinks, slug]);
      }
    },
    [visitedLinks],
  );

  const handleMouseEnter = debounce((index) => {
    if (!isDragging) {
      setHighlighted(index);
    }
  }, 0);

  const handleMouseLeave = () => {
    setHighlighted(-1);
  };

  return (
    <Container>
      {/* Heed z-index here to make sure it fits under the navbar but under gradients below*/}
      <DesktopOnly>
        {/* Using tailwind bg-transparency causes a transition to transparent black rather than transparent white */}
        <AlphaGradient tw="[left:-4rem] [background:linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0))]" />
        {/* Calc below is left screen width minus width of element minus the left margin on the timeline */}
        <AlphaGradient tw=" [left:calc(90vw - 4rem)] lg:([left:calc(100vw - 8rem)]) [background:linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))]" />
      </DesktopOnly>
      <motion.div
        tw="relative z-10 inset-0 [padding-bottom:5rem] select-none"
        css={[
          motionContainerCss,
          isDragging ? tw`[cursor:grabbing]` : tw`[cursor:grab]`,
        ]}
        onPan={handlePan}
        onPanStart={() => setIsDragging(true)}
        onPanEnd={() => setIsDragging(false)}
      >
        <motion.ul ref={containerRef} tw="flex" style={{ x }}>
          {selectedNodes.map((item, i, array) => {
            const visited = visitedLinks.includes(item.slug);
            return (
              <motion.li
                tw="relative"
                css={liStyle(i, array.length)}
                key={`link-${item.slug}-${i}`} // indexing for testing w/ repeat data
              >
                <TimelineItem
                  onMouseEnter={() => handleMouseEnter(i)}
                  onMouseLeave={handleMouseLeave}
                  onLinkClick={handleLinkClick}
                  hasVisited={visited}
                  selected={highlighted === i}
                  caseName={item.case_name}
                  caseYear={item.case_year}
                  shortName={item.short_name}
                  width={itemWidth}
                  image={item.timeline_image_data}
                  {...item}
                />
              </motion.li>
            );
          })}
        </motion.ul>
      </motion.div>
    </Container>
  );
};

const Container = tw.div`relative ml-8 w-full mt-auto`;
const AlphaGradient = tw.div`absolute inset-y-0 [width:10vw] z-20 lg:([width:4rem])`;

const motionContainerCss = css`
  padding-top: 70vh;
  @media only screen and (min-width: 330px) {
    padding-top: 60vh;
  }
  @media only screen and (min-width: 768px) {
    padding-top: 50vh;
  }
`;
const liStyle = (index, arrayLength) => [
  index !== 0 &&
    css`
      margin-left: ${itemMargin}px;
    `,
  index !== arrayLength - 1 &&
    css`
      margin-right: ${itemMargin}px;
    `,
];
