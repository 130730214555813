import { Button } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { FC, useMemo } from 'react';
import tw, { css } from 'twin.macro';
import { Category, CustomCssProps } from '~/config';

type CategoryButtonProps = CustomCssProps & {
  //
  handleClick: () => void;
  selectedCat: string;
  category: Category;
};

export const CategoryButton: FC<CategoryButtonProps> = ({
  handleClick,
  category,
  selectedCat,
  customCss,
}) => {
  const transition = useMemo(
    () => ({
      duration: 0.25,
      ease: 'easeOut',
    }),
    [],
  );

  const rectVariants = useMemo(
    () => [
      {
        unselected: {
          x: 0,
          width: 0,
        },
        selected: {
          width: 31,
        },
        hover: {
          width: 124,
        },
      },
      {
        unselected: {
          x: 0,
          width: 0,
        },
        selected: {
          x: 31,
          width: 31,
        },
        hover: {
          x: 124,
          width: 7.75,
        },
      },
      {
        unselected: {
          x: 0,
          width: 0,
        },
        selected: {
          x: 62,
          width: 31,
        },
        hover: {
          x: 131.75,
          width: 7.75,
        },
      },
      {
        unselected: {
          x: 0,
          width: 0,
        },
        selected: {
          x: 93,
          width: 31,
        },
        hover: {
          x: 139.5,
          width: 7.75,
        },
      },
      {
        unselected: {
          x: 0,
          width: 0,
        },
        selected: {
          x: 124,
          width: 31,
        },
        hover: {
          x: 147.25,
          width: 7.75,
        },
      },
    ],
    [],
  );
  return (
    <Button
      tw="relative md:([max-width:15em])"
      key={category.slug}
      css={[buttonStyle(category, selectedCat === category.slug), customCss]}
      onClick={handleClick}
    >
      <motion.div
        whileHover="hover"
        animate={
          selectedCat.toLowerCase() === 'all' ? 'selected' : 'unselected'
        }
      >
        <span tw="z-10 relative">
          {category.title === 'All' ? 'All Cases' : category.title}
        </span>
        {category.title === 'All' && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 155 54"
            preserveAspectRatio="none"
            tw="absolute inset-0 w-full h-full [z-index:0]"
          >
            <motion.rect
              id="Rectangle_8"
              data-name="Rectangle 8"
              height="54"
              fill="rgb(var(--brand-earth))"
              stroke="rgb(var(--brand-earth))"
              transition={transition}
              variants={rectVariants[0]}
            />
            <motion.rect
              id="Rectangle_9"
              data-name="Rectangle 9"
              height="54"
              transition={transition}
              variants={rectVariants[1]}
              fill="rgb(var(--brand-coral))"
              stroke="rgb(var(--brand-coral))"
            />
            <motion.rect
              id="Rectangle_10"
              data-name="Rectangle 10"
              height="54"
              transition={transition}
              variants={rectVariants[2]}
              fill="rgb(var(--brand-red-delicious))"
              stroke="rgb(var(--brand-red-delicious))"
            />
            <motion.rect
              id="Rectangle_11"
              data-name="Rectangle 11"
              height="54"
              transition={transition}
              variants={rectVariants[3]}
              fill="rgb(var(--brand-pumpkin))"
              stroke="rgb(var(--brand-pumpkin))"
            />
            <motion.rect
              id="Rectangle_12"
              data-name="Rectangle 12"
              height="54"
              transition={transition}
              variants={rectVariants[4]}
              fill="rgb(var(--brand-sky-blue))"
              stroke="rgb(var(--brand-sky-blue))"
            />
          </svg>
        )}
      </motion.div>
    </Button>
  );
};

const buttonStyle = (category: Category, selected: boolean) => {
  if (category.title === 'All') {
    return [
      tw`[transition:all 1s ease-out]
      border border-black
      whitespace-normal
      flex justify-start h-10 px-3 py-1
      text-left font-bold text-sm md:(leading-none) rounded-none hover:(text-white)
      `,
      selected && tw`text-white`,
    ];
  } else {
    return [
      tw`border border-black rounded-none whitespace-normal
      flex justify-start h-10 px-3 py-1
      text-left font-bold text-sm md:(leading-none)`,
      css`
        transition: all 0.2s ease-out;
        color: ${selected ? 'white' : category.color};
        background-color: ${selected ? category.color : 'white'};
        &:hover {
          color: white;
          background-color: ${category.color};
          opacity: 0.9;
        }
      `,
    ];
  }
};
